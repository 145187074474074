<template>
  <div class="role-management">
    <div class="title">
      <span class="s">角色列表</span>
    </div>
    <div class="search-cell">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="角色名称：">
          <el-input
            v-model="formInline.role_name"
            size="mini"
            placeholder="角色名称"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="状态：">
          <el-select size="mini" v-model="formInline.status" placeholder="全部">
            <el-option label="全部" value=""></el-option>
            <el-option label="启用" value="1"></el-option>
            <el-option label="禁用" value="0"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-button
            size="mini"
            type="primary"
            @click="
              page = 1;
              getRoleList();
            "
            >查询</el-button
          >
          <!-- <el-button size="mini" type="success" @click="addRole(0)"
            >新增</el-button
          > -->
          <el-button size="mini" plain @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tab">
      <el-table
        size="small "
        v-loading="loading"
        border
        :data="tableData.list"
        style="width: 100%; text-algin: center"
        :header-cell-style="{ background: '#F2F2F2', 'border-color': '#000' }"
        :row-style="{ 'border-color': '#000' }"
        :cell-style="{ 'border-color': '#000' }"
        :header-row-style="{ 'border-color': '#000' }"
      >
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="id"
          label="序号"
          width="80"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="role_name"
          label="角色名称"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="description"
          label="描述"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="time"
          label="创建时间"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.time | formatTimeS("all") }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="number"
          label="用户数"
        >
        </el-table-column>
        <!-- <el-table-column
          show-overflow-tooltip
          align="center"
          prop="status"
          label="启用状态"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.status == 0 ? "禁用" : "启用" }}</span>
          </template>
        </el-table-column> -->
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="address"
          label="操作"
        >
          <template slot-scope="scope">
            <!-- <span @click="handleStop(scope.row)" class="btn">{{
              scope.row.status == 1 ? "停用" : "启用"
            }}</span
            > -->
            <span
              v-if="scope.row.id != 1"
              class="btn"
              @click="addRole(scope.row.id)"
              >编辑</span
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        small
        style="margin-top: 20px"
        background
        layout="prev, pager, next"
        :total="tableData.total"
        :page-size="page_size"
        :current-page="page"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { roleList, delRole } from "@/api/admin.js";
export default {
  data() {
    return {
      formInline: {
        role_name: "",
        status: "",
      },
      page: 1,
      page_size: 10,
      tableData: {
        list: [],
      },
      loading: true,
    };
  },
  created() {
    this.getRoleList();
  },
  methods: {
    handleStop(val) {

      let text = val.status == 1 ? "停用" : "启用";
      this.$confirm("确定要" + text + "该角色吗?", text + "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delRole({
          id: val.id,
          status: val.status == 1 ? 0 : 1,
        }).then((res) => {
          if (res.code == 1) {
            this.$message({
              type: "success",
              message: text + "成功!",
            });
            val.status = val.status == 1 ? 0 : 1;
            this.$forceUpdate();
          }
        });
      });
    },
    // 获取列表
    getRoleList() {
      this.loading = true;
      roleList({
        ...this.formInline,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          this.tableData = res.data;
        }
      });
    },
    // 跳转新增/编辑
    addRole(id) {

      if (id) {
        sessionStorage.ROLEID = id;
      } else {
        sessionStorage.ROLEID = "0";
      }
      this.$router.push("addRole");
    },
    // 重置
    handleReset() {
      this.formInline = {
        role_name: "",
        status: "",
      };
      this.page = 1;
      this.getRoleList();
    },
    // 删除操作
    handleDel() {
      this.$confirm("确定要删除该角色吗?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    },
    pageChange(index) {
      this.page = index;
      this.getRoleList();
    },
  },
};
</script>
<style lang="less">
.role-management {
  background: #fff;
  padding: 20px;
  .title {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    .s {
      font-size: 14px;
      color: #ee4d2d;
      border-bottom: 2px solid #ee4d2d;
      padding: 0 10px;
      padding-bottom: 10px;
    }
  }
  .search-cell {
    padding: 10px 0;
  }
  .tab {
    .btn {
      font-size: 12px;
      cursor: pointer;
      margin: 0 10px;
    }
    .btn:hover {
      border-bottom: 1px solid #000;
    }
  }
}
.el-form-item__label {
  font-size: 12px;
}
.el-table--border .el-table__cell {
  border-color: #000;
}
</style>